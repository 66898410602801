import { Route, Routes } from 'react-router-dom';
import './App.scss';
import Router from './config/Router';

function App() {
  return (
    <>
      <Router/>
    </>
  );
}

export default App;
